import * as Types from '@aeppic/types'

import { EventEmitter, IEventEmitter } from '@aeppic/shared/event-emitter'
import { waitForNextMicroTick, waitForNextTick } from '@aeppic/shared/wait-for-tick'

import type { FindOptions } from '../model'

export interface IQueryResult {
}

export interface IQueryStats {
}

export interface IQuerySubscription extends IEventEmitter {
  documents: Types.Document[]
  cancel(): void
  updateQuery(queryString: string, options?: FindOptions)
  refresh(newDocumentsPromise: Promise<Types.Document[]>)
}

/**
 * Subscribe to changes in list. Documents get updated correctly but
 * it does NOT emit an event if a documents changes itself, just additions
 * and removals in the list
 */
export class QuerySubscription extends EventEmitter implements IQuerySubscription {
  private _cancelled = false
  private _pending = 0
  private _refreshCount = 0
  private _lastInfo = {
    added: [],
    updated: [],
    removed: [],
    moved: [],
  }

  public documents = null

  constructor(private _queryString: string, private _options: FindOptions, private _initialDocuments: Types.Document[]) {
    super()

    this.cancel = this.cancel.bind(this)
    
    const copyOfArray = [..._initialDocuments]
    this.documents = copyOfArray

    const initialRefresh = async () => {
      await waitForNextMicroTick()
      return copyOfArray
    }

    this.refresh(initialRefresh())
  }

  updateQuery(queryString: string, options?: FindOptions): boolean {
    let changed = false

    if (queryString !== this._queryString) {
      this._queryString = queryString
      changed = true
    }

    if (JSON.stringify(options) !== JSON.stringify(this._options)) {
      this._options = options ?? this._options
      changed = true
    }

    return changed
  }

  public get cancelled() { return this._cancelled }

  public cancel() {
    this._cancelled = true
    this._pending = 0
  }
  
  public get queryString() {
    return this._queryString
  }

  public get findOptions() {
    return this._options
  }

  public get isRefreshing() {
    return this._pending > 0
  }

  private _willRefresh() {
    this._pending += 1
  }

  private _refreshed() {
    this._pending = this._pending - 1 || 0
  }

  public async refresh(newDocumentsPromise: Promise<Types.Document[]>) {
    if (this._cancelled) {
      return
    }

    this._willRefresh()

    try {
      this._refreshCount += 1
      const refreshCount = this._refreshCount

      const newDocuments = await newDocumentsPromise

      if (refreshCount !== this._refreshCount) {
        return
      }

      const removed = this._findDocumentsNotInList(newDocuments)
      const { added, updated, moved } = this._updateExistingDocuments(newDocuments)

      // This check is necessary because added,updated,moved are not 100% trustworthy (ask @mgoetzke).
      //  Rewrite if added,updated,moved are tested and trustworthy.
      if (this._anyDocumentDifferent(this.documents, newDocuments)) {
        this.documents.splice(0, this.documents.length, ...newDocuments)
      }

      if (added.length > 0 || removed.length > 0 || updated.length > 0 || moved.length > 0) {
        this._lastInfo = {
          removed,
          added,
          updated,
          moved,
        }
      }
    } finally {
      this._refreshed()

      if (this._pending === 0) {
        await waitForNextTick()
        this._emitRefreshed(this._lastInfo)
      }
    }
  }

  private _findDocumentsNotInList(acceptableDocs: Types.Document[]) {
    const removed = []

    for (const oldDocument of this.documents) {
      let found = false

      for (const acceptableDoc of acceptableDocs) {
        if (oldDocument.id === acceptableDoc.id) {
          found = true
          break
        }
      }

      if (!found) {
        removed.push(oldDocument)
      }
    }

    return removed
  }

  private _updateExistingDocuments(docs: Types.Document[]) {
    const added = []
    const updated = []
    const moved = []

    for (let i = 0; i < docs.length; i += 1) {
      const newDocument = docs[i]

      let wasAdded = true
      let wasUpdated = false
      let wasMoved = false

      for (let oi = 0; oi < this.documents.length; oi += 1) {
        const oldDocument = this.documents[oi]

        if (newDocument.id === oldDocument.id) {
          wasAdded = false

          if (newDocument.v === oldDocument.v) {
            docs[i] = oldDocument // Keep using old document

            if (i !== oi) {
              wasMoved = true
            }
          } else {
            wasUpdated = true
          }
          break
        }
      }

      if (wasAdded) {
        added.push(newDocument)
      }

      if (wasUpdated) {
        updated.push(newDocument)
      }

      if (wasMoved) {
        moved.push(newDocument)
      }
    }

    return { added, updated, moved }
  }

  private _emitRefreshed(info: { added: Types.Document[], updated: Types.Document[], removed: Types.Document[], moved: Types.Document[] }) {
    if (this._pending === 0) {
      const { added, removed, updated, moved } = info

      if (added.length > 0 || removed.length > 0 || updated.length > 0 || moved.length > 0) {
        this.emit('refreshed', info)
      }

      this._resetLastInfo()
    }
  }

  private _resetLastInfo() {
    this._lastInfo = {
      added: [],
      updated: [],
      removed: [],
      moved: [],
    }
  }

  private _anyDocumentDifferent(documentsA: Types.Document[], documentsB: Types.Document[]) {
    const changed = documentsA.length !== documentsB.length || 
                    documentsB.some((document, index) => document?.id !== documentsA[index]?.id || document?.v !== documentsA[index]?.v)
  
    return changed
  } 
}